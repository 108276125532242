import { AdUnitCategory } from 'ad-framework/ad/index.types';

export enum SlotType {
  BLOCK = 'block',
  INLINE = 'inline',
  INLINE_LEFT = 'inline_left',
  INLINE_RIGHT = 'inline_right',
  INLINE_LEFT_RIGHT = 'inline_left_right',
  GENERATED = 'generated',
}
export enum SlotPosition {
  BEFORE = 'before',
  AFTER = 'after',
  PREPEND = 'prepend',
  APPEND = 'append',
}
export enum SlotLabelPosition {
  ABOVE = 'above',
  BELOW = 'below',
}
export enum SlotAlign {
  CENTER = 'center',
}
export enum SlotStatus {
  OFF_SCREEN = 'OFF_SCREEN',
  NEARLY_VISIBLE = 'NEARLY_VISIBLE',
  VISIBLE = 'VISIBLE',
}

export interface DynamicSlotConfig {
  root: string;
}

export interface AdditionalAvoidance {
  hook: string;
  elements: Array<HTMLElement>;
}

export interface FillGenerationConfig {
  containerStyle: { [key: string]: string };
  spacerStyle: { [key: string]: string };
  maximumSlots: number;
  minimumSlots: number;
  endSelector?: string;
  slotHeight?: number;
  slotGap?: number;
}

export interface SlotLabel {
  text?: string;
  style?: string;
  position?: string;
  applyLabelToAds?: string;
}
export type CompanionBound = 'screenheight' | number;
export interface CompanionBounds {
  above: CompanionBound;
  below: CompanionBound;
}

export interface SlotDefinition {
  name: string;
  width: number;
  height: number;
  fluid: boolean;
  hook: string;
  multiple: boolean;
  ignoreErrors: boolean;
  adCategoryAllowList: Array<AdUnitCategory>;
  position: SlotPosition;
  type: SlotType;
  additionalAvoidance: AdditionalAvoidance[];
  ignoreExclusion: boolean;
  label: SlotLabel;
  style: { [key: string]: string };
  sponsoredSlotActivationDistanceOverride: number;
  master?: string;
  nativeContent: boolean;
  companionBounds: Partial<CompanionBounds>;
  dynamicConfig?: DynamicSlotConfig;
  generatedConfig?: FillGenerationConfig;
}
interface BaseDynamicSlotDefinition extends SlotDefinition {
  dynamicConfig: DynamicSlotConfig;
}
export type DynamicSlotDefinition =
  | (BaseDynamicSlotDefinition & { type: Exclude<SlotType, SlotType.GENERATED> })
  | (GeneratedSlotDefinition & BaseDynamicSlotDefinition);
export interface GeneratedSlotDefinition extends SlotDefinition {
  type: SlotType.GENERATED;
  generatedConfig: FillGenerationConfig;
}

export type AnySlotDefinition = SlotDefinition | DynamicSlotDefinition | GeneratedSlotDefinition;

export interface SlotAdditions {
  id: string;
  genericName: string;
  hookElement: HTMLElement;
  coordinates: { top: number; left: number; right: number; bottom: number };
  adID?: string;
  masterID?: string;
  element: HTMLElement;
  excluded: boolean;
  readyPromise?: Promise<void>;
}
export type BasicSlot = Omit<SlotDefinition, 'ignoreErrors'> & SlotAdditions;
export type DynamicSlot = Omit<DynamicSlotDefinition, 'ignoreErrors'> & SlotAdditions;
export type GeneratedSlot = Omit<GeneratedSlotDefinition, 'ignoreErrors'> & SlotAdditions;
export type Slot = BasicSlot | DynamicSlot | GeneratedSlot;
