import { ThirdPartyAPIMachineConfigs } from 'state/types/context.types';
import loadScript from 'third-party-apis/utils/load-script';
import PartialPick from 'utils/partial-pick.types';
import { ThirdParty } from '../config.types';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.TMT], 'thirdParty'> = {
  thirdParty: ThirdParty.TMT,
  methods: {
    getScriptLocation: ({
      data: {
        config: { configurationId },
      },
    }) => `https://scripts.webcontentassessor.com/scripts/${configurationId}`,
    loadScript: (scriptLocation): Promise<void> =>
      loadScript(scriptLocation, 'bordeaux-tmt', { async: true }),
  },
};
export default config;
