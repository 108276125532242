import { ThirdParty } from 'third-party-apis/config.types';
import { SommelierResponse } from 'utils/ad-server/sommelier-request/sommelier-response.types';

const config: SommelierResponse = {
  placement: {
    id: 99998,
    platformId: -1,
    linkedIds: [],
    siteAdUnitPath: '/10518929/HAAS-Fallback',
    placementAdUnitPath: 'fallback',
    slots: [],
    adunits: [],
    settings: {
      refreshTime: 20,
      adDensity: {
        avoidanceDistance: 400,
      },
    },
  },
  config: {
    [ThirdParty.AD_SERVER]: { enabled: true },
    [ThirdParty.AM_CLIO]: { enabled: false },
    [ThirdParty.AMAZON]: { enabled: true },
    [ThirdParty.IAS]: { enabled: true },
    [ThirdParty.INDEX_EXCHANGE]: { enabled: false, id: '', mode: 'standard' },
    [ThirdParty.LIVE_RAMP]: { enabled: false, configurationId: '' },
    [ThirdParty.PUBMATIC]: { enabled: false, profileId: '' },
    [ThirdParty.PREBID]: { enabled: false },
    [ThirdParty.TMT]: { enabled: false, configurationId: '' },
  },
};

export default config;
