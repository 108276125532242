import { setup } from 'xstate';

import { BordeauxConfig } from 'config/zod-schema';
import { SommelierResponse } from 'utils/ad-server/sommelier-request/sommelier-response.types';
import { fallbackAction, fetchConfig, checkConfig, parseConfig, parsedFallback } from 'config';
import { getGDPRConsentMachine, getUSPConsent, getGPPConsent } from 'utils/cmp';
import {
  CMP_EVENTS,
  GDPRConsentStatus,
  USPConsentStatus,
  GPPConsentStatus,
} from 'utils/cmp/index.types';
import onAdMount from 'ad-framework/ad/on-ad-mount';
import onSlotMount from 'ad-framework/slot/on-slot-mount';

import { FullAPISetupResults } from 'third-party-apis/index.types';
import { setupThirdPartyAPI } from 'third-party-apis/index';
import { ThirdParty } from 'third-party-apis/config.types';

import ias from 'third-party-apis/ias';
import indexExchange from 'third-party-apis/index-exchange';
import liveRamp from 'third-party-apis/liveramp';
import prebid from 'third-party-apis/prebid';
import pubmatic from 'third-party-apis/pubmatic';
import adServer from 'third-party-apis/ad-server';
import amClio from 'third-party-apis/am-clio';
import amazon from 'third-party-apis/amazon';
import gpt from 'third-party-apis/gpt';
import euid from 'third-party-apis/euid';
import defaultFallbacks from 'config/fallback';
import pubx from 'third-party-apis/pubx';
import tmt from 'third-party-apis/the-media-trust';
import getParameters from 'parameters';

import { getEnv } from 'utils/env';
import { AuctionPayload, Payload } from 'timing-collection/payload.types';
import { getHybridAbTestTargeting } from 'ad-framework/targeting';
import elementTapHandler from 'utils/element-tap-handler';
import { getEnvDOMContentLoaded } from 'utils/env-promises';
import loadAdTool from 'utils/ad-tool/load';
import setupAPI from 'api';
import isAdBlocked from 'utils/ad-block-test';
import initAutomaticRefresh from 'ad-framework/refresh/automatic';
import { REFRESH_EVENTS } from 'ad-framework/refresh/automatic/index.types';
import triggerAutomaticRefresh from 'ad-framework/refresh/automatic/action';
import getRoadblockStatus from 'ad-framework/roadblock';
import { accessObjectPath } from 'utils/object';
import { mergeConfigs } from 'third-party-apis/merge-configs';
import trimSlashes from 'utils/trim-slashes';
import { FEATURE } from 'api/feature';
import TimeoutError from 'utils/error/timeout';
import { timeData } from 'utils/timestamp';
import { API_EVENTS_IN, API_EVENTS_OUT } from 'api/events.names';
import { ThirdPartyEvent } from 'third-party-apis/events.names';
import { CompanionBounds, Slot } from 'ad-framework/slot/index.types';
import {
  BATCH_ALONE,
  BATCH_FIRST,
  Ad,
  AdDefinition,
  AdUnitCategory,
  AdUnitMode,
  AdUnitStatus,
} from 'ad-framework/ad/index.types';
import listenForAdFeatures from 'ad-features/post-message-listener';
import { AD_FEATURES_EVENTS } from 'ad-features/events.names';
import fullWidth from 'ad-features/full-width';
import slotifyMachine from 'slotify';
import log from 'log/index';
import popOut from 'ad-features/pop-out';
import multiFrame from 'ad-features/multi-frame';
import expandHeight from 'ad-features/expand-height';
import createAnchoredAdMarkup from 'ad-framework/ad/handle-create/anchored';
import createSkyscraperMarkup from 'ad-framework/ad/handle-create/skyscraper';
import createAd from 'ad-framework/ad';
import { passesAdditionalAvoidance } from 'slotify/avoidance';
import findBestAdForSlot from 'slotify/find-best-ad-for-slot';
import { overlapsExclusionZones } from 'slotify/exclusion';
import checkAdUnitCountries from 'utils/check-adunit-countries';
import autoIncrementTargeting from 'ad-framework/ad/auto-increment-targeting';
import anchoredLogic from 'ad-framework/ad/anchored-logic';
import createOutOfPageMarkup from 'ad-framework/ad/handle-create/out-of-page';
import * as actions from './actions';
import * as report from './report';
import DataObjectStore from './data-object-store';
import { STATES, ACTIONS, GUARDS } from './types/index.types';
import {
  SetHybridABTestTargetingEvent,
  SetRoadblockEvent,
  RequestBordeauxContextEvent,
  RequestBordeauxDataEvent,
  ContextErrorEvent,
  RequestBatchEvent,
  AdsCreatedEvent,
} from './types/events.types';
import AnyBordeauxEvent from './types/any-event.types';
import { EVENTS } from './types/events.names';
import {
  Metrics,
  REPORT,
  ReportEvent,
  REPORT_THIRD_PARTY_SCRIPT,
  ThirdPartyReportEvent,
} from './types/report.types';
import { ACTIONS_RECORD } from './report';
import {
  IncrementalAdsRoadblockStatusEvent,
  INCREMENTAL_ADS_EVENTS_IN,
  STANDARD_ADS_EVENTS_OUT,
  SLOTIFY_EVENTS_OUT,
  AdMatchesEvent,
} from '../slotify/index.types';
import assign, { assignParams } from './proxy/assign';
import enqueueActions from './proxy/enqueueActions';
import sendTo from './proxy/send-to';
import raise from './proxy/raise';
import {
  BordeauxMachineContext,
  ProvideBordeauxContextEvent,
  ProvideBordeauxDataEvent,
} from './types/context.types';
import userSyncPixels from '../user-sync/user-sync-config';
import waitForAllThirdParties from './actors/wait-for-all-third-parties';
import readPageStyles from './actors/read-page-styles';
import pageEventEmitter from './actors/page-event-emitter';
import arbitraryEventEmitter from './actors/arbitrary-event-emitter';
import ActionArgs from './proxy/action-args.types';
import DataObject from './data-object';
import adBatchMachine from './ad-batch';
import apiEvents from './api';

export default setup({
  types: {} as {
    context: BordeauxMachineContext;
    events: AnyBordeauxEvent;
  },
  actors: {
    arbitraryEventEmitter,
    elementTapHandler,
    initAutomaticRefresh,
    pageEventEmitter,
    getEnvDOMContentLoaded,
    setupAPI,
    fetchConfig,
    checkConfig,
    parseConfig,
    getGDPRConsentMachine,
    getUSPConsent,
    getGPPConsent,

    setupThirdPartyAPI,

    listenForAdFeatures,
    waitForAllThirdParties,
    readPageStyles,
    anchoredLogic,
    slotifyMachine,
    adBatchMachine,
  },
  guards: {
    [GUARDS.TIMING_COLLECTION_ENABLED]: ({ context }) => context.timing.enabled,
    [GUARDS.ERROR_IS_TIMEOUT]: ({ event }) =>
      event.type === REPORT.CONFIG_FAILURE && event.data.error instanceof TimeoutError,
    [GUARDS.DUPLICATE_SCRIPTS]: () => {
      const env = getEnv();
      return (
        (
          env.document.querySelectorAll(
            'script[src="https://bordeaux.futurecdn.net/bordeaux.js"]',
          ) || []
        ).length > 1
      );
    },
    [GUARDS.ADS_IN_BATCH]: ({ event }) =>
      event.type === EVENTS.REQUEST_BATCH && event.data.length > 0,
    [GUARDS.ROADBLOCK_INCREMENTALS_FILLED]: ({
      context: {
        isRoadblock,
        roadblockIncrementalCaps,
        roadblockIncrementalCount,
        pageParameters: { device },
        config: {
          placement: {
            settings: { roadblock },
          },
        },
      },
    }) => {
      if (!isRoadblock) return false;

      const incrementalCap = roadblockIncrementalCaps?.[device] ?? roadblock?.incrementalCap;

      if (!incrementalCap) {
        return true;
      }

      if (roadblockIncrementalCount >= incrementalCap) {
        return true;
      }

      return false;
    },
    [GUARDS.PROCESSING_SLOTS_CURRENTLY]: ({ context: { slotStackProcessing } }) =>
      slotStackProcessing,
    [GUARDS.SLOTS_TO_PROCESS]: ({ context: { slotStack } }) => slotStack.length !== 0,
  },
  actions: {
    ...report.recordActions,
    ...report.reportActions,
    [ACTIONS.CREATE_API_MACHINE]: assign({
      externalApiMachine: ({ spawn }) => spawn('setupAPI'),
    }),
    [ACTIONS.CREATE_AD_FEATURE_MACHINE]: assign({
      adFeatureMachine: ({ spawn }) => spawn('listenForAdFeatures'),
    }),
    [ACTIONS.INITIALISE_FEATURES]: assign({
      featuresInitialised: true,
    }),

    [ACTIONS.CHECK_AD_BLOCK]: assign({
      adBlocked: isAdBlocked,
    }),
    [ACTIONS.READ_QUERY_PARAMETERS]: assign({
      queryParameters: () => {
        const env = getEnv();
        const query = new URLSearchParams(env.location.search);
        return {
          country: query.get('force_locale') || query.get('force_country') || query.get('CC'),
          debugTool: query.get('debug_ads'),
          forceRoadblock: query.get('force_roadblock'),
          forceTargeting: query.get('force_targeting'),
          sommelierUrl: query.get('sommelier_url'),
          forcePLCDB: query.get('force_plc_db'),
          forceABTestControl: query.get('force_abtest_control'),
          forceABTestVariant: query.get('force_abtest_variant'),
          lrh: query.get('lrh'),
        };
      },
    }),
    [ACTIONS.READ_PAGE_PARAMETERS]: assign({
      pageParameters: getParameters,
    }),
    [ACTIONS.CREATE_SHAMEFUL_MACHINE]: assign({
      arbitraryEventEmitter: ({ context, spawn }) =>
        spawn('arbitraryEventEmitter', { input: { pageParameters: context.pageParameters } }),
    }),
    [ACTIONS.ASSIGN_INDEX_EXCHANGE_DEVICE_TYPE]: ({ context }) => {
      const env = getEnv();
      // Allows index exchange to pick the right kind of ads depending on the current device
      env.indexExchangeDeviceType = context.pageParameters.device;
    },
    [ACTIONS.ASSIGN_BORDEAUX_ADS_PROMISE]: () => {
      const env = getEnv();
      // Resolve if page is using Bordeaux ads
      env.bordeauxAds = Promise.resolve(true);
    },
    [ACTIONS.CHECK_MULTIPLE_SCRIPTS]: enqueueActions(({ check, enqueue }) => {
      if (check(GUARDS.DUPLICATE_SCRIPTS)) enqueue(REPORT.MULTIPLE_SCRIPTS);
    }),
    [ACTIONS.CREATE_ADTOOL_TAP_OPEN_MACHINE]: assign({
      adToolTapOpenMachine: ({ spawn }) =>
        spawn('elementTapHandler', {
          input: {
            element: document.body,
            fingers: 2,
            repetitions: 10,
          },
        }),
    }),
    [ACTIONS.CHECK_ADTOOL_PARAM]: enqueueActions(({ check, enqueue }) => {
      if (check(({ context }) => context.queryParameters.debugTool !== null))
        enqueue(ACTIONS.RAISE_OPEN_AD_TOOL);
    }),
    [ACTIONS.CREATE_AUTOMATIC_REFRESH_MACHINE]: assign({
      automaticRefreshMachine: ({ spawn }) => spawn('initAutomaticRefresh'),
    }),
    [ACTIONS.REPORT_IF_AD_BLOCKED]: enqueueActions(({ check, enqueue }) => {
      if (check(({ context }) => context.adBlocked)) enqueue(REPORT.AD_BLOCKED);
    }),

    [ACTIONS.USE_PARSED_FALLBACK_CONFIG]: assign({
      config: parsedFallback,
    }),
    [ACTIONS.USE_FALLBACK_CONFIG]: assign({
      sommelierResponse: fallbackAction,
    }),

    [ACTIONS.RAISE_OPEN_AD_TOOL]: raise({
      type: EVENTS.OPEN_AD_TOOL,
    }),
    [ACTIONS.THIRD_PARTIES_READY]: sendTo<BordeauxMachineContext['externalApiMachine']>(
      ({ context }) => context.externalApiMachine,
      {
        type: API_EVENTS_IN.THIRD_PARTIES_READY,
      },
    ),
    [ACTIONS.DECIDE_AVOIDANCE_DISTANCE]: assign({
      avoidanceDistance: ({ context }) => {
        const configAvoidanceDistance =
          context.config.placement.settings.adDensity?.avoidanceDistance;
        const apiAvoidanceDistanceConfig = context.deviceAvoidanceDistance;

        if (apiAvoidanceDistanceConfig) {
          const { device } = context.pageParameters;
          return apiAvoidanceDistanceConfig[device];
        }
        if (configAvoidanceDistance === undefined) {
          return context.avoidanceDistance;
        }
        return configAvoidanceDistance;
      },
    }),
    [ACTIONS.DECIDE_TEST_PUBX]: actions.decidePubxAbTest,
    [ACTIONS.DECIDE_TEST_ID_SERVICE_ACTIVATION]: actions.decideIdsActivationTest,
    [ACTIONS.DECIDE_THIRD_PARTY_CONFIG]: assign({
      thirdPartyApiConfig: ({ context }) =>
        mergeConfigs(context.config.thirdPartyAPIConfig, context.thirdPartyApiConfigOverrides),
    }),
    [ACTIONS.DECIDE_LIVE_INTENT_USER_SYNC]: assign({
      liveIntentUserSyncEnabled: ({ context }) =>
        context.thirdPartyApiConfig.liveIntent.enabled && Math.random() < 0.9,
    }),
    [ACTIONS.DECIDE_PAGE_ADUNIT_PATH]: assign({
      pageAdUnitPath: ({ context }) =>
        `/${[
          context.config.placement.siteAdUnitPath,
          ...(!context.pageCategory ? [] : [context.pageCategory]),
          context.config.placement.adUnitPath,
        ]
          .map(trimSlashes)
          .join('/')}`,
    }),
    [ACTIONS.INITIALISE_SENTRY]: report.initialiseSentry,
    [ACTIONS.SEND_AB_TEST_TO_FREYR]: actions.handleHybridAbTestsIfAny,
    [ACTIONS.SETUP_CUSTOM_ACTIVATIONS]: actions.setupCustomActivations,
    [ACTIONS.DECIDE_REFRESH_TIME]: actions.decideRefreshTime,
    [ACTIONS.SETUP_USER_SYNC]: actions.setupUserSync,
    [ACTIONS.SETUP_AD_MANAGER]: actions.setupAdManager,
    [ACTIONS.HANDLE_ERROR]: actions.handleError,
    [ACTIONS.ASSIGN_LIVE_INTENT_USER_SYNC_TARGETING]: enqueueActions(({ check, enqueue }) => {
      if (
        check(
          ({ context }): boolean =>
            context.thirdPartyResults[ThirdParty.PREBID].config.enabled &&
            context.thirdPartyResults[ThirdParty.PREBID].success,
        )
      ) {
        if (check(({ context }) => context.liveIntentUserSyncEnabled)) {
          enqueue(
            assign({
              pageTargeting: ({ context }): BordeauxMachineContext['pageTargeting'] => ({
                ...context.pageTargeting,
                'li-module-enabled': ['on'],
              }),
            }),
          );
        } else {
          enqueue(
            assign({
              pageTargeting: ({ context }): BordeauxMachineContext['pageTargeting'] => ({
                ...context.pageTargeting,
                'li-module-enabled': ['off'],
              }),
            }),
          );
        }
      }
    }),
    [ACTIONS.STORE_HYBRID_TEST_SESSIONS]: actions.storeHybridTestSessions,
    [ACTIONS.DECIDE_TEST_AUCTION_TIMEOUTS]: actions.decideTestAuctionTimeouts,

    [ACTIONS.CREATE_ADS]: raise(
      ({ event, context: { adCounter, pageAdUnitPath, adTypeCounters } }) =>
        event.type === SLOTIFY_EVENTS_OUT.AD_MATCHES
          ? ({
              type: EVENTS.ADS_CREATED,
              data: event.data.map(({ slot, adDefinition, batch = BATCH_ALONE }, index, list) => ({
                slot,
                ad: createAd(adDefinition, {
                  id: `bordeaux-ad-${adCounter + index}`,
                  ...(adDefinition.incremental
                    ? {
                        targeting: {
                          ...autoIncrementTargeting(adDefinition),
                          adUnitName: [adDefinition.name],
                        },
                        // ADP-10451: "limit the ad unit path on incremental ad units to a maximum of ten"
                        adUnitPath: `${pageAdUnitPath}/${adDefinition.name}-${Math.min(10, list.slice(0, index).reduce((counter, item) => counter + (item.adDefinition.name === adDefinition.name ? 1 : 0), 0) + (adTypeCounters[adDefinition.name] || 0))}`,
                      }
                    : {
                        adUnitPath: `${pageAdUnitPath}/${adDefinition.name}`,
                      }),
                  batch,
                }),
              })),
            } as AdsCreatedEvent)
          : ({
              type: EVENTS.CONTEXT_ERROR,
              data: ACTIONS.CREATE_ADS,
            } as ContextErrorEvent),
    ),

    [ACTIONS.ADD_ADS]: ({ event, context: { ads } }) => {
      if (event.type !== EVENTS.ADS_CREATED) return;
      event.data.forEach(({ ad }) => {
        ads.push(ad);
      });
    },
    [ACTIONS.INCREMENT_AD_TYPE_COUNTERS]: assign({
      adTypeCounters: ({ context: { adTypeCounters }, event }) =>
        event.type === SLOTIFY_EVENTS_OUT.AD_MATCHES
          ? event.data.reduce(
              (adTypeCounters, { adDefinition }) => ({
                ...adTypeCounters,
                [adDefinition.name]: 1 + (adTypeCounters[adDefinition.name] || 0),
              }),
              adTypeCounters,
            )
          : adTypeCounters,
    }),
    [ACTIONS.FIRST_AD_BATCH]: raise(
      ({ context: { ads } }): RequestBatchEvent => ({
        type: EVENTS.REQUEST_BATCH,
        data: ads.getValues().filter(ad => ad.getProperty('batch') === BATCH_FIRST),
      }),
    ),
    [ACTIONS.AD_BATCH]: assignParams<Array<DataObject<Ad>>>({
      adBatchMachines: ({ context: { adBatchMachines }, spawn }, params) => [
        ...adBatchMachines,
        spawn('adBatchMachine', {
          input: params,
        }),
      ],
    }),
    [ACTIONS.DECIDE_ROADBLOCK_INCREMENTALS]: assign({
      roadblockIncrementals: ({
        context: {
          roadblockIncrementalChooser,
          adUnits: { incremental: adDefinitions },
        },
      }) => {
        const incrementalChooser = roadblockIncrementalChooser;
        return adDefinitions.filter((ad: AdDefinition): boolean => {
          if (incrementalChooser) {
            const apiChooserOverride = incrementalChooser(ad);
            if (apiChooserOverride === false) return false;
            if (apiChooserOverride === true) return true;
          }
          return Boolean(ad.roadblockIncremental);
        });
      },
    }),
    [ACTIONS.INCREMENT_AD_COUNTER]: assign({
      adCounter: ({ context: { adCounter }, event }) =>
        event.type === SLOTIFY_EVENTS_OUT.AD_MATCHES ? adCounter + event.data.length : adCounter,
    }),
    [ACTIONS.INCREMENT_ROADBLOCK_INCREMENTAL_COUNTER]: assign({
      roadblockIncrementalCount: ({
        context: { isRoadblock, incrementalsStarted, roadblockIncrementalCount },
        event,
      }) =>
        event.type === SLOTIFY_EVENTS_OUT.AD_MATCHES
          ? roadblockIncrementalCount +
            (incrementalsStarted && isRoadblock
              ? event.data.filter(({ slot }) => !(slot && slot.getProperty('nativeContent'))).length
              : 0)
          : roadblockIncrementalCount,
    }),
    [ACTIONS.DECIDE_VALID_ADUNITS]: assign({
      adUnits: ({
        context: {
          pageParameters: { country },
          config: {
            placement: { adUnits },
          },
        },
      }) => ({
        standard: adUnits.standard.filter(checkAdUnitCountries(country.toLowerCase())),
        incremental: adUnits.incremental.filter(checkAdUnitCountries(country.toLowerCase())),
      }),
    }),
    [ACTIONS.HIDE_ANCHORED]: ({ context }) => {
      context.ads
        .getValues()
        .filter(ad => ad.getProperty('mode') === AdUnitMode.ANCHORED)
        .forEach(ad => {
          const element = ad.getProperty('element');
          if (element) {
            element.style.transform = 'translate(0, 100%)';
            element.style.transition = '.5s';
          }
        });
    },
    [ACTIONS.SHOW_ANCHORED]: ({ context }) => {
      context.ads
        .getValues()
        .filter(ad => ad.getProperty('mode') === AdUnitMode.ANCHORED)
        .forEach(ad => {
          const element = ad.getProperty('element');
          if (element) {
            element.style.transform = 'translate(0, 0)';
            element.style.transition = '.5s';
          }
        });
    },
  },
}).createMachine({
  id: 'bordeaux',
  initial: STATES.INITIALISING,
  context: {
    config: {} as BordeauxConfig,
    fallbackResponses: defaultFallbacks,
    sommelierResponse: {} as SommelierResponse,
    slots: new DataObjectStore<Slot>(),
    ads: new DataObjectStore<Ad>(),
    adUnits: { incremental: [], standard: [] },
    adBatch: [],
    adBatchStack: [],
    adBatchMachines: [],
    adStackProcessing: false,
    standardAdIndex: 0,
    incrementalAdIndex: 0,
    incrementalBatchIndex: 0,
    roadblockIncrementalCount: 0,
    roadblockIncrementals: [],
    anchoredRefreshDisabled: false,
    adCounter: 0,
    adTypeCounters: {},
    incrementalsStarted: false,
    timing: {
      payload: {} as Payload,
      auctionPayloads: {} as Record<number, AuctionPayload>,
      endpoint: `https://eventsproxy.gargantuan.futureplc.com/future.adtech.bordeaux.v1.AdRequestCompletedEvent`,
      unsentAuctions: [],
      sampleRate: 1,
      enabled: false,
    },
    thirdPartyMachines: {} as BordeauxMachineContext['thirdPartyMachines'],
    thirdPartyResults: {} as FullAPISetupResults,
    metrics: {
      [Metrics.THIRD_PARTY_SCRIPTS]: {},
      [Metrics.AUCTIONS]: {},
    },
    auctions: {},
    gdprConsent: {
      done: false,
      askedForConsent: false,
      consent: null,
      status: GDPRConsentStatus.NOT_APPLICABLE,
      hasEnoughConsentForAuction: false,
    },
    uspConsent: {
      done: false,
      askedForConsent: false,
      consent: null,
      ccpaApplies: false,
      status: USPConsentStatus.NOT_APPLICABLE,
    },
    gppConsent: {
      done: false,
      consent: null,
      status: GPPConsentStatus.NOT_APPLICABLE,
    },
    experimentId: '',
    hybridABTestTargeting: [],
    hybridId: '',
    arbitraryEventEmitter: {} as BordeauxMachineContext['arbitraryEventEmitter'],
    adFeatureMachine: {} as BordeauxMachineContext['adFeatureMachine'],
    pageEventEmitter: {} as BordeauxMachineContext['pageEventEmitter'],
    cftParameters: {
      labelName: 'UNDECIDED',
    } as BordeauxMachineContext['cftParameters'],
    pageParameters: {} as BordeauxMachineContext['pageParameters'],
    queryParameters: {} as BordeauxMachineContext['queryParameters'],
    adToolTapOpenMachine: {} as BordeauxMachineContext['adToolTapOpenMachine'],
    automaticRefreshMachine: {} as BordeauxMachineContext['automaticRefreshMachine'],
    externalApiMachine: {} as BordeauxMachineContext['externalApiMachine'],
    pageStyleConstants: {} as BordeauxMachineContext['pageStyleConstants'],
    anchoredMachine: {} as BordeauxMachineContext['anchoredMachine'],
    adBlocked: false,
    isRoadblock: null,
    loadGptExternallyPromise: ((): BordeauxMachineContext['loadGptExternallyPromise'] => {
      let resolve;
      const promise = new Promise<boolean>(res => {
        resolve = res;
      });
      return {
        promise,
        resolve,
      };
    })(),
    userSyncPixels,
    slotStack: [],
    slotStackProcessing: false,
    // API
    overrideCompanionBounds: {},
    unrefreshableNames: [],
    unrefreshableLineItems: [],
    unrefreshableOrders: [],
    unrefreshableAdvertisers: [],
    unrefreshableStatuses: [AdUnitStatus.PENDING],
    unrefreshableModes: [AdUnitMode.OOP],
    unrefreshableCategories: [AdUnitCategory.SPONSORED_POST],
    adToolVersion: '',
    loadGptExternally: false,
    prebidAnalyticsEnabled: false,
    auctionTimeouts: undefined,
    automaticDynamic: true,
    pageCategory: undefined,
    pageTemplate: undefined,
    refreshTime: 0,
    roadblockIncrementalCaps: null,
    roadblockIncrementalChooser: null,
    activationDistance: 1200,
    deviceAvoidanceDistance: undefined,
    avoidanceDistance: 400,
    thirdPartyApiConfig: {} as BordeauxMachineContext['thirdPartyApiConfig'],
    thirdPartyApiConfigOverrides: {},
    pageTargeting: {},
    pageAdUnitPath: 'undecided',
    features: {
      [FEATURE.ADS_INCREMENTAL]: true,
      [FEATURE.ADS_STANDARD]: true,
      [FEATURE.ADS_REFRESH]: true,
    },
    featuresInitialised: false,
    liveIntentUserSyncEnabled: false,
  },
  states: {
    [STATES.INITIALISING]: {
      entry: [
        ACTIONS.CREATE_AD_FEATURE_MACHINE,
        assign({
          timing: ({ context }) => ({
            ...context.timing,
            enabled: Math.random() < context.timing.sampleRate,
          }),
        }),
        assign({
          pageEventEmitter: ({ spawn }) => spawn('pageEventEmitter'),
        }),
      ],
      invoke: {
        src: 'getEnvDOMContentLoaded',
        onDone: {
          actions: raise(
            ({ event }): ReportEvent<REPORT.CONTENT_LOAD> => ({
              type: REPORT.CONTENT_LOAD,
              data: { time: event.output },
            }),
          ),
          target: STATES.SCRAPING_PARAMATERS,
        },
        onError: STATES.ERROR,
      },
    },
    [STATES.SCRAPING_PARAMATERS]: {
      entry: [
        ACTIONS.CHECK_AD_BLOCK,
        ACTIONS.READ_QUERY_PARAMETERS,
        ACTIONS.READ_PAGE_PARAMETERS,
        ACTIONS_RECORD.INITIALISE_PAYLOAD,
        ACTIONS.CREATE_SHAMEFUL_MACHINE,
        ACTIONS.ASSIGN_INDEX_EXCHANGE_DEVICE_TYPE,
        ACTIONS.ASSIGN_BORDEAUX_ADS_PROMISE,
        ACTIONS.CHECK_MULTIPLE_SCRIPTS,
        ACTIONS.CREATE_ADTOOL_TAP_OPEN_MACHINE,
        ACTIONS.CHECK_ADTOOL_PARAM,
        ACTIONS.CREATE_AUTOMATIC_REFRESH_MACHINE,
        ACTIONS.REPORT_IF_AD_BLOCKED,
        ACTIONS.CREATE_API_MACHINE,
      ],
      on: {
        [EVENTS.SET_EXTERNAL_API]: {
          actions: [
            ({ event }): void => {
              const env = getEnv();
              // Casting to any to allow us to attach to the env without types which would
              // cause circular reference warnings.
              (env as any).bordeaux = event.data; // eslint-disable-line @typescript-eslint/no-explicit-any
              (env as any).bdx = event.data; // eslint-disable-line @typescript-eslint/no-explicit-any
            },
            sendTo(({ context }) => context.externalApiMachine, {
              type: API_EVENTS_IN.API_READY,
            }),
          ],
        },
        [API_EVENTS_OUT.COMMANDS_RUN]: [
          {
            guard: ({
              event: {
                data: { initialised },
              },
            }) => initialised,
            target: STATES.FETCHING_CONFIG,
          },
          {
            target: STATES.WAIT_FOR_INITIALISATION,
          },
        ],
      },
    },
    [STATES.WAIT_FOR_INITIALISATION]: {
      on: {
        [API_EVENTS_OUT.INITIALISE]: {
          target: STATES.FETCHING_CONFIG,
        },
      },
    },
    [STATES.FETCHING_CONFIG]: {
      entry: [
        REPORT.FRAMEWORK_REQUEST,
        ACTIONS.INITIALISE_SENTRY,
        ACTIONS.INITIALISE_FEATURES,
        REPORT.CONFIG_REQUEST,
      ],
      invoke: {
        src: 'fetchConfig',
        input: ({ context }) => ({
          pageTemplate: context.pageTemplate,
        }),
        onDone: {
          actions: [
            REPORT.CONFIG_LOAD,
            assign({
              sommelierResponse: ({ event }): SommelierResponse => event.output,
            }),
          ],
          target: STATES.CHECKING_CONFIG,
        },
        onError: {
          actions: [
            raise(
              ({ event }): ReportEvent<REPORT.CONFIG_FAILURE> => ({
                type: REPORT.CONFIG_FAILURE,
                data: {
                  time: timeData(),
                  error: event.error as Error,
                },
              }),
            ),
            ACTIONS.USE_FALLBACK_CONFIG,
          ],
          target: STATES.PARSING_CONFIG,
        },
      },
    },
    [STATES.CHECKING_CONFIG]: {
      invoke: {
        src: 'checkConfig',
        input: ({ context }) => ({ sommelierResponse: context.sommelierResponse }),
        onDone: STATES.PARSING_CONFIG,
        onError: {
          actions: [REPORT.CONFIG_EMPTY, ACTIONS.USE_FALLBACK_CONFIG],
          target: STATES.PARSING_CONFIG,
        },
      },
    },
    [STATES.PARSING_CONFIG]: {
      invoke: {
        src: 'parseConfig',
        input: ({ context }) => ({
          sommelierResponse: context.sommelierResponse,
        }),
        onDone: {
          actions: [
            assign({
              config: ({ event }): BordeauxConfig => event.output,
            }),
            REPORT.CONFIG_SUCCESS,
            ACTIONS.SEND_AB_TEST_TO_FREYR,
            ACTIONS.DECIDE_AVOIDANCE_DISTANCE,
            ACTIONS.DECIDE_TEST_PUBX,
            ACTIONS.DECIDE_TEST_ID_SERVICE_ACTIVATION,
            ACTIONS.DECIDE_THIRD_PARTY_CONFIG,
            ACTIONS.DECIDE_PAGE_ADUNIT_PATH,
            ACTIONS.DECIDE_VALID_ADUNITS,
            ACTIONS.DECIDE_ROADBLOCK_INCREMENTALS,
            sendTo<BordeauxMachineContext['externalApiMachine']>(
              ({ context }) => context.externalApiMachine,
              {
                type: API_EVENTS_IN.CONFIG_READY,
              },
            ),
            ACTIONS.STORE_HYBRID_TEST_SESSIONS,
            ACTIONS.DECIDE_TEST_AUCTION_TIMEOUTS,
          ],
          target: STATES.RETRIEVING_CONSENT,
        },
        onError: {
          actions: [
            ACTIONS.USE_PARSED_FALLBACK_CONFIG,
            REPORT.CONFIG_PARSE_FAILURE,
            ACTIONS.SEND_AB_TEST_TO_FREYR,
            ACTIONS.DECIDE_AVOIDANCE_DISTANCE,
            ACTIONS.DECIDE_THIRD_PARTY_CONFIG,
            ACTIONS.DECIDE_PAGE_ADUNIT_PATH,
            ACTIONS.DECIDE_VALID_ADUNITS,
            ACTIONS.DECIDE_ROADBLOCK_INCREMENTALS,
            sendTo<BordeauxMachineContext['externalApiMachine']>(
              ({ context }) => context.externalApiMachine,
              {
                type: API_EVENTS_IN.CONFIG_READY,
              },
            ),
          ],
          target: STATES.RETRIEVING_CONSENT,
        },
      },
    },
    [STATES.RETRIEVING_CONSENT]: {
      entry: [
        ACTIONS.SETUP_CUSTOM_ACTIVATIONS,
        ACTIONS.DECIDE_REFRESH_TIME,
        REPORT.CONSENT_REQUEST,
      ],
      invoke: [
        {
          src: 'getUSPConsent',
          onDone: {
            actions: [
              assign({
                uspConsent: ({ event }) => event.output,
              }),
              assign({
                pageTargeting: ({ context, event }) => ({
                  ...context.pageTargeting,
                  _usp_status: event.output.status,
                }),
              }),
              raise({
                type: EVENTS.CONSENT_DONE,
              }),
            ],
          },
          onError: {
            target: STATES.ERROR,
          },
        },
        {
          src: 'getGDPRConsentMachine',
          onDone: {
            actions: [
              REPORT.CONSENT_SUCCESS,
              assign({
                gdprConsent: ({ event }) => event.output,
              }),
              assign({
                pageTargeting: ({ context, event }) => ({
                  ...context.pageTargeting,
                  _gdpr_status: event.output.status,
                }),
              }),
              raise({
                type: EVENTS.CONSENT_DONE,
              }),
            ],
          },
          onError: {
            actions: REPORT.CONSENT_FAILURE,
            target: STATES.ERROR,
          },
        },
        {
          src: 'getGPPConsent',
          onDone: {
            actions: [
              assign({
                gppConsent: ({ event }) => event.output,
              }),
              assign({
                pageTargeting: ({ context, event }) => ({
                  ...context.pageTargeting,
                  _gpp_status: event.output.status,
                }),
              }),
              raise({
                type: EVENTS.CONSENT_DONE,
              }),
            ],
          },
          onError: {
            target: STATES.ERROR,
          },
        },
      ],
      on: {
        [CMP_EVENTS.PENDING]: {
          actions: REPORT.CONSENT_PENDING,
        },
        [CMP_EVENTS.LOADED]: {
          actions: REPORT.CONSENT_LOADED,
        },
        [CMP_EVENTS.MOCKED]: {
          actions: REPORT.CONSENT_MOCKED,
        },
        [EVENTS.CONSENT_DONE]: {
          guard: ({ context }) => context.uspConsent.done && context.gdprConsent.done,
          target: STATES.LOADING_THIRD_PARTIES,
        },
      },
    },
    [STATES.LOADING_THIRD_PARTIES]: {
      entry: [
        REPORT.THIRD_PARTY_REQUEST,
        ACTIONS.DECIDE_LIVE_INTENT_USER_SYNC,
        assign({
          thirdPartyMachines: ({ spawn, context }) => {
            const bordeaux: Pick<
              BordeauxMachineContext,
              | 'config'
              | 'liveIntentUserSyncEnabled'
              | 'gdprConsent'
              | 'loadGptExternallyPromise'
              | 'loadGptExternally'
              | 'thirdPartyApiConfig'
              | 'sommelierResponse'
              | 'pageTargeting'
            > = {
              config: context.config,
              gdprConsent: context.gdprConsent,
              liveIntentUserSyncEnabled: context.liveIntentUserSyncEnabled,
              thirdPartyApiConfig: context.thirdPartyApiConfig,
              loadGptExternallyPromise: context.loadGptExternallyPromise,
              loadGptExternally: context.loadGptExternally,
              sommelierResponse: context.sommelierResponse,
              pageTargeting: context.pageTargeting,
            };
            return {
              [ThirdParty.AD_SERVER]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.AD_SERVER}`,
                input: { bordeaux, thirdPartyMethods: adServer },
              }),
              [ThirdParty.AM_CLIO]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.AM_CLIO}`,
                input: { bordeaux, thirdPartyMethods: amClio },
              }),
              [ThirdParty.AMAZON]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.AMAZON}`,
                input: { bordeaux, thirdPartyMethods: amazon },
              }),
              [ThirdParty.EUID]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.EUID}`,
                input: { bordeaux, thirdPartyMethods: euid },
              }),
              [ThirdParty.GPT]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.GPT}`,
                input: { bordeaux, thirdPartyMethods: gpt },
              }),
              [ThirdParty.IAS]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.IAS}`,
                input: { bordeaux, thirdPartyMethods: ias },
              }),
              [ThirdParty.INDEX_EXCHANGE]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.INDEX_EXCHANGE}`,
                input: { bordeaux, thirdPartyMethods: indexExchange },
              }),
              [ThirdParty.LIVE_RAMP]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.LIVE_RAMP}`,
                input: { bordeaux, thirdPartyMethods: liveRamp },
              }),
              [ThirdParty.PREBID]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.PREBID}`,
                input: { bordeaux, thirdPartyMethods: prebid },
              }),
              [ThirdParty.PUBMATIC]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.PUBMATIC}`,
                input: { bordeaux, thirdPartyMethods: pubmatic },
              }),
              [ThirdParty.PUBX]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.PUBX}`,
                input: { bordeaux, thirdPartyMethods: pubx },
              }),
              [ThirdParty.TMT]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.TMT}`,
                input: { bordeaux, thirdPartyMethods: tmt },
              }),
            } as BordeauxMachineContext['thirdPartyMachines'];
          },
        }),
      ],
      invoke: {
        src: 'waitForAllThirdParties',
        input: ({ context }) => ({ thirdPartyMachines: context.thirdPartyMachines }),
        onDone: {
          actions: [
            REPORT.THIRD_PARTY_SUCCESS,
            assign({
              thirdPartyResults: ({ event }) => event.output,
            }),
            ACTIONS.ASSIGN_LIVE_INTENT_USER_SYNC_TARGETING,
            ACTIONS.THIRD_PARTIES_READY,
          ],
          target: STATES.DECIDING_PAGE_STYLE_CONSTANTS,
        },
        onError: STATES.ERROR,
      },
      on: {
        [ThirdPartyEvent.THIRD_PARTY_REQUEST]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.REQUEST> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.REQUEST,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
        [ThirdPartyEvent.THIRD_PARTY_SUCCESS]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.SUCCESS> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.SUCCESS,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
        [ThirdPartyEvent.THIRD_PARTY_TIMEOUT]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.TIMEOUT> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.TIMEOUT,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
        [ThirdPartyEvent.THIRD_PARTY_FAILURE]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.FAILURE> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.FAILURE,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
      },
    },
    [STATES.DECIDING_PAGE_STYLE_CONSTANTS]: {
      invoke: {
        src: 'readPageStyles',
        onDone: {
          actions: assign({
            pageStyleConstants: ({ event }) => event.output,
          }),
          target: STATES.HANDLING_SLOTS,
        },
      },
    },

    [STATES.HANDLING_SLOTS]: {
      entry: [
        ACTIONS.SETUP_USER_SYNC,
        raise(
          ({ context }): SetHybridABTestTargetingEvent => ({
            type: EVENTS.SET_HYBRID_ABTEST_TARGETING,
            data: getHybridAbTestTargeting(context),
          }),
        ),
        assign({
          anchoredMachine: ({ spawn, context }) =>
            spawn('anchoredLogic', {
              input: {
                slots: context.slots,
                ads: context.ads,
                config: context.config,
                pageParameters: context.pageParameters,
              },
            }),
        }),
        ACTIONS.SETUP_AD_MANAGER,
      ],
      invoke: {
        src: 'slotifyMachine',
        id: 'slotify',
        input: ({ context }) => ({
          slotDefinitions: context.config.placement.slots,
          adDefinitions: context.adUnits.standard,
          activationDistance: context.activationDistance,
          avoidanceDistance: context.avoidanceDistance,
          pageStyleConstants: context.pageStyleConstants,
          pageAdUnitPath: context.pageAdUnitPath,
          automaticDynamic: context.automaticDynamic,
          features: context.features,
          isRoadblock: context.isRoadblock,
        }),
        onError: STATES.ERROR,
      },
      on: {
        [STANDARD_ADS_EVENTS_OUT.FAILURE]: {
          target: STATES.ERROR,
        },
      },
    },
    [STATES.ERROR]: {
      entry: ACTIONS.HANDLE_ERROR,
    },
  },
  on: {
    ...report.reportEvents,
    ...apiEvents,
    [AD_FEATURES_EVENTS.FULL_WIDTH]: {
      actions: fullWidth,
    },
    [AD_FEATURES_EVENTS.POP_OUT]: {
      actions: popOut,
    },
    [AD_FEATURES_EVENTS.MULTI_FRAME]: {
      actions: multiFrame,
    },
    [AD_FEATURES_EVENTS.EXPAND_HEIGHT]: {
      actions: expandHeight,
    },
    [EVENTS.SET_HYBRID_ABTEST_TARGETING]: {
      actions: [
        assign({
          hybridABTestTargeting: ({ event }) => event.data,
        }),
        ACTIONS_RECORD.HYBRID_ABTEST_TARGETING,
      ],
    },
    [EVENTS.SET_HYBRID_ID]: {
      actions: [
        assign({
          hybridId: ({ event }) => event.data,
        }),
        ACTIONS_RECORD.HYBRID_ID,
        sendTo(({ context }) => context.externalApiMachine, {
          type: API_EVENTS_IN.HYBRID_ID_READY,
        }),
        actions.sendUserIdsToFreyr,
      ],
    },
    [EVENTS.SET_CFT_PARAMETERS]: {
      actions: [
        assign({
          cftParameters: ({ event }) => event.data,
        }),
        ACTIONS_RECORD.CFT_PARAMETERS,
      ],
    },
    [EVENTS.PAGE_UNLOAD]: {
      actions: enqueueActions(({ enqueue, check }) => {
        if (check(GUARDS.TIMING_COLLECTION_ENABLED)) enqueue(report.pageUnload);
      }),
    },
    [EVENTS.PAGE_LOAD]: {
      actions: report.pageLoad,
    },
    [EVENTS.OPEN_AD_TOOL]: {
      actions: loadAdTool,
    },
    [EVENTS.CHECK_ROADBLOCK_STATUS]: {
      actions: enqueueActions(({ check, enqueue }) => {
        if (check(({ context }) => context.isRoadblock === null)) {
          if (check(({ context }) => getRoadblockStatus(context) !== null)) {
            enqueue(
              raise(
                ({ context }): SetRoadblockEvent => ({
                  type: EVENTS.SET_ROADBLOCK_STATUS,
                  data: getRoadblockStatus(context) as boolean,
                }),
              ),
            );
          }
        }
      }),
    },
    [EVENTS.SET_ROADBLOCK_STATUS]: {
      actions: [
        assign({
          isRoadblock: ({ event }) => event.data,
        }),
        sendTo<BordeauxMachineContext['automaticRefreshMachine'], SetRoadblockEvent>(
          ({ context }) => context.automaticRefreshMachine,
          ({ context }) => ({
            type: REFRESH_EVENTS.SET_ROADBLOCK,
            data: Boolean(context.isRoadblock),
          }),
        ),
        sendTo(
          'slotify',
          ({ context }): IncrementalAdsRoadblockStatusEvent => ({
            type: INCREMENTAL_ADS_EVENTS_IN.ROADBLOCK_STATUS,
            data: Boolean(context.isRoadblock),
          }),
        ),
        sendTo(({ context }) => context.externalApiMachine, {
          type: API_EVENTS_IN.ROADBLOCK_READY,
        }),

        enqueueActions(
          ({
            enqueue,
            context: {
              isRoadblock,
              adUnits: { standard: adDefinitions },
            },
          }) => {
            // ADP-12918 anchored ads should be avoided if roadblock is active
            if (isRoadblock) return;

            adDefinitions
              .filter(
                adDefinition =>
                  adDefinition.mode === AdUnitMode.ANCHORED && !adDefinition.inRoadblock,
              )
              .forEach(adDefinition => {
                enqueue(
                  raise({
                    type: SLOTIFY_EVENTS_OUT.AD_MATCHES,
                    data: [
                      {
                        adDefinition,
                      },
                    ],
                  } as AdMatchesEvent),
                );
              });
          },
        ),
      ],
    },
    [EVENTS.AUTOMATIC_REFRESH]: {
      actions: triggerAutomaticRefresh,
    },

    [EVENTS.REQUEST_BORDEAUX_DATA]: {
      actions: sendTo<BordeauxMachineContext['externalApiMachine'], RequestBordeauxDataEvent>(
        ({ context }) => context.externalApiMachine,
        ({ context, event }): ProvideBordeauxDataEvent => ({
          type: EVENTS.PROVIDE_BORDEAUX_DATA,
          data: {
            value: accessObjectPath(context, event.data.property),
            requestId: event.data.requestId,
          },
        }),
      ),
    },

    [EVENTS.REQUEST_BORDEAUX_CONTEXT]: {
      actions: sendTo<BordeauxMachineContext['externalApiMachine'], RequestBordeauxContextEvent>(
        ({ context }) => context.externalApiMachine,
        ({ context, event }): ProvideBordeauxContextEvent => ({
          type: EVENTS.PROVIDE_BORDEAUX_CONTEXT,
          data: {
            value: context,
            requestId: event.data.requestId,
          },
        }),
      ),
    },
    [EVENTS.HIDE_ANCHORED_ADS]: [
      {
        guard: ({ event }) => Boolean(event.data?.infiniteScrollIntersection),
        actions: [
          ACTIONS.HIDE_ANCHORED,
          assign({
            anchoredRefreshDisabled: true,
          }),
        ],
      },
      {
        actions: [ACTIONS.HIDE_ANCHORED],
      },
    ],
    [EVENTS.SHOW_ANCHORED_ADS]: [
      {
        guard: ({ event }) => Boolean(event.data?.infiniteScrollIntersection),
        actions: [
          ACTIONS.SHOW_ANCHORED,
          assign({
            anchoredRefreshDisabled: false,
          }),
        ],
      },
      {
        actions: [ACTIONS.SHOW_ANCHORED],
      },
    ],

    [SLOTIFY_EVENTS_OUT.AD_BATCH]: {
      actions: [
        ACTIONS.FIRST_AD_BATCH,
        assign({
          incrementalsStarted: true,
        }),
        report.adsLoaded,
      ],
    },
    [SLOTIFY_EVENTS_OUT.AD_MATCHES]: {
      actions: [
        ACTIONS.CREATE_ADS,
        ACTIONS.INCREMENT_AD_COUNTER,
        ACTIONS.INCREMENT_ROADBLOCK_INCREMENTAL_COUNTER,
        ACTIONS.INCREMENT_AD_TYPE_COUNTERS,
      ],
    },
    [SLOTIFY_EVENTS_OUT.NEW_SLOT]: {
      actions: ({ context, event }) => {
        context.slots.push(event.data);
      },
    },
    [EVENTS.ADS_CREATED]: {
      actions: [
        ACTIONS.ADD_ADS,
        ({ event }) => {
          event.data.forEach(({ slot, ad }) => {
            if (slot) {
              ad.update({ slotID: slot.getProperty('id') });
              const targeting = ad.getProperty('targeting');
              ad.update({
                targeting: {
                  ...targeting,
                  _slot: slot.getProperty('name'),
                  _slot_type: slot.getProperty('genericName'),
                },
              });
              slot.update({ adID: ad.getProperty('id') });

              onAdMount(ad);
              onSlotMount(slot, ad);
            } else {
              switch (ad.getProperty('mode')) {
                case AdUnitMode.ANCHORED:
                  ad.update({ readyPromise: createAnchoredAdMarkup(ad) });
                  break;
                case AdUnitMode.OOP:
                  ad.update({ readyPromise: createOutOfPageMarkup(ad) });
                  break;
                case AdUnitMode.SKYSCRAPER:
                  ad.update({ readyPromise: createSkyscraperMarkup(ad) });
                  break;
                default:
                  log.error(`Add ad handler error - no mode set: ${JSON.stringify(ad, null, 2)}`);
                  break;
              }
            }
          });
        },
        enqueueActions(({ enqueue, check }) => {
          if (check(GUARDS.PROCESSING_SLOTS_CURRENTLY)) {
            if (check(GUARDS.SLOTS_TO_PROCESS)) {
              enqueue(
                raise({
                  type: EVENTS.PROCESS_NEXT_SLOT,
                }),
              );
            } else {
              enqueue(
                assign({
                  slotStackProcessing: false,
                }),
              );
            }
          }
        }),
      ],
    },
    [EVENTS.REQUEST_BATCH]: {
      actions: enqueueActions(({ enqueue, check, event }) => {
        if (!check(GUARDS.ADS_IN_BATCH)) return;

        if (check(({ context: { adStackProcessing } }) => adStackProcessing)) {
          enqueue(
            assign<RequestBatchEvent>({
              adBatchStack: ({ context: { adBatchStack }, event }) => [...adBatchStack, event.data],
            }),
          );
        } else {
          enqueue(
            assign({
              adStackProcessing: true,
            }),
          );
          enqueue({ type: ACTIONS.AD_BATCH, params: event.data });
        }
      }),
    },

    [SLOTIFY_EVENTS_OUT.SLOT_IN_VIEW]: {
      actions: [
        assign({
          slotStack: ({ context: { slotStack }, event: { data: slot } }) => [...slotStack, slot],
        }),
        enqueueActions(({ enqueue, check }) => {
          if (!check(GUARDS.PROCESSING_SLOTS_CURRENTLY)) {
            enqueue(
              assign({
                slotStackProcessing: true,
              }),
            );
            enqueue(
              raise({
                type: EVENTS.PROCESS_NEXT_SLOT,
              }),
            );
          }
        }),
      ],
    },
    [EVENTS.PROCESS_NEXT_SLOT]: {
      actions: [
        enqueueActions(actionArgs => {
          const {
            context: { slotStack, slots, overrideCompanionBounds, config },
            enqueue,
            check,
          } = actionArgs;

          let matches: AdMatchesEvent['data'] | null = null;

          const slot = slotStack[0];
          // Makes sure we never exceed the roadblock incremental cap (except native content)
          if (check(GUARDS.ROADBLOCK_INCREMENTALS_FILLED) && !slot.getProperty('nativeContent'))
            return;
          const masterName = slot.getProperty('genericName');
          const companionDefinitions = config.placement.slots.static.filter(
            slotDefinition => slotDefinition.master === masterName,
          );

          if (companionDefinitions.length) {
            const potentialCompanions = slots
              .getValues()
              .filter(
                slot => slot.getProperty('master') === masterName && !slot.getProperty('masterID'),
              );
            const companionSets = companionDefinitions.map(slotDefinition =>
              potentialCompanions.filter(
                companion => companion.getProperty('genericName') === slotDefinition.name,
              ),
            );

            const comanionsAvailable = companionSets.every(companionSet => companionSet.length > 0);
            if (comanionsAvailable) {
              const env = getEnv();
              const defaultPageBounds: CompanionBounds = { above: 1, below: 800 };
              const pageBounds: CompanionBounds = {
                ...defaultPageBounds,
                ...overrideCompanionBounds,
                ...slot.getProperty('companionBounds'),
              };

              const bounds = slot.getProperty('element').getBoundingClientRect();
              const lowerBound =
                pageBounds.below === 'screenheight' ? env.innerHeight : pageBounds.below;
              const upperBound =
                pageBounds.above === 'screenheight' ? env.innerHeight : pageBounds.above;

              const closeCompanions = companionSets.map(companionSet => {
                return companionSet.find(otherSlot => {
                  const otherBounds = otherSlot.getProperty('element').getBoundingClientRect();
                  if (otherBounds.top - bounds.top > lowerBound) return false;
                  if (bounds.top - otherBounds.bottom > upperBound) return false;
                  return true;
                });
              });
              const allCompanionsValid = closeCompanions.every(
                (otherSlot): otherSlot is DataObject<Slot> => Boolean(otherSlot),
              );
              if (allCompanionsValid) {
                // Tandem Slots
                closeCompanions.forEach(companionSlot => {
                  companionSlot.update({ masterID: slot.getProperty('id') });
                });
                const batch = `${slot.getProperty('id')}-${Date.now()}-${Math.random()}`;
                const allSlots = [slot, ...closeCompanions];
                matches = allSlots.reduce((matches: null | AdMatchesEvent['data'], tandemSlot) => {
                  if (!matches) return matches; // If any failed, short-circuit: return null

                  const adDefinition = findAdDefinition(tandemSlot, actionArgs);
                  if (!adDefinition) return null;

                  matches.push({
                    slot: tandemSlot,
                    adDefinition,
                    batch,
                  });
                  return matches;
                }, []);
              }
            }
          } else if (!slot.getProperty('master')) {
            // Normal slot
            const adDefinition = findAdDefinition(slot, actionArgs);
            if (adDefinition) {
              matches = [
                {
                  slot,
                  adDefinition,
                  batch: BATCH_ALONE,
                },
              ];
            }
          }

          if (matches) {
            enqueue(
              raise({
                type: SLOTIFY_EVENTS_OUT.AD_MATCHES,
                data: matches,
              } as AdMatchesEvent),
            );
            enqueue(
              raise(
                {
                  type: EVENTS.REQUEST_AUTOMATIC_BATCH,
                },
                {
                  delay: 10,
                },
              ),
            );
          } else if (slotStack.length > 1) {
            enqueue(
              raise({
                type: EVENTS.PROCESS_NEXT_SLOT,
              }),
            );
          } else {
            enqueue(
              assign({
                slotStackProcessing: false,
              }),
            );
          }
        }),
        assign({
          slotStack: ({ context: { slotStack } }) => slotStack.slice(1),
        }),
      ],
    },
    [EVENTS.REQUEST_AUTOMATIC_BATCH]: {
      actions: enqueueActions(({ context, enqueue }) => {
        const ads = context.ads
          .getValues()
          .filter(
            ad =>
              ad.getProperty('status') === AdUnitStatus.PENDING &&
              ad.getProperty('batch') !== BATCH_FIRST,
          );
        const batches = Object.values(
          ads.reduce((batches, ad, index) => {
            const batch = ad.getProperty('batch');
            if (batch === BATCH_ALONE) {
              batches[index] = [ad];
            } else {
              batches[batch] = batches[batch] || [];
              batches[batch].push(ad);
            }
            return batches;
          }, {}),
        );

        ads.forEach(ad => ad.update({ status: AdUnitStatus.BATCHED }));
        batches.forEach(batch => {
          enqueue(
            raise({
              type: EVENTS.REQUEST_BATCH,
              data: batch,
            } as RequestBatchEvent),
          );
        });
      }),
    },
    [EVENTS.PROCESS_NEXT_BATCH]: [
      {
        guard: ({ context: { adBatchStack } }) => adBatchStack.length !== 0,
        actions: [
          { type: ACTIONS.AD_BATCH, params: ({ context: { adBatchStack } }) => adBatchStack[0] },
          assign({
            adBatchStack: ({ context: { adBatchStack } }) => adBatchStack.slice(1),
          }),
        ],
      },
      {
        actions: assign({
          adStackProcessing: false,
        }),
      },
    ],
  },
});

const findAdDefinition = (slot: DataObject<Slot>, { context }: ActionArgs): null | AdDefinition => {
  const {
    isRoadblock,
    avoidanceDistance,
    roadblockIncrementals,
    adUnits: { incremental: adDefinitions },
    slots,
    ads,
  } = context;
  const selectedAdDefinitions =
    isRoadblock && !slot.getProperty('nativeContent') ? roadblockIncrementals : adDefinitions;

  if (selectedAdDefinitions.length === 0) {
    return null;
  }

  if (slot.getProperty('adID') !== undefined) {
    return null;
  }
  if (!passesAdditionalAvoidance(avoidanceDistance, slot)) {
    return null;
  }
  if (!slot.getProperty('ignoreExclusion') && overlapsExclusionZones(slot)) {
    return null;
  }
  return selectedAdDefinitions.reduce(
    findBestAdForSlot(
      {
        slots: slots.getValues(),
        ads: ads.getValues(),
        avoidanceDistance,
      },
      slot,
    ),
    null,
  );
};
