import { z } from 'zod';
import { ThirdParty } from './config.types';
import { prebidBiddersSchema } from './prebid/zod-schema';

export const thirdPartyAPIConfigSchema = z
  .object({
    [ThirdParty.GLOBAL]: z
      .object({
        sellerID: z.string().nullish().default(null),
        enabled: z.boolean().default(true),
      })
      .default({}),
    [ThirdParty.GPT]: z
      .object({
        enabled: z.boolean().default(true),
      })
      .default({}),
    [ThirdParty.AD_SERVER]: z
      .object({
        enabled: z.boolean().default(false),
        ab: z.array(z.number()).default([]),
      })
      .default({}),
    [ThirdParty.AMAZON]: z
      .object({
        enabled: z.boolean().default(false),
        consentVendor: z.string().default('793'),
        apiConfig: z
          .object({
            pubID: z.string().default('3032'),
            adServer: z.string().default('googletag'),
            useSafeFrames: z.boolean().default(true),
            deals: z.boolean().default(true),
            schain: z
              .object({
                complete: z.literal(0).or(z.literal(1)).default(1), // Integer 1 or 0 indicating if all preceding nodes are complete
                ver: z.string().default('1.0'), // Version of the spec used
                nodes: z
                  .array(
                    z.object({
                      // Can be empty if the owner of the site is creating this request
                      asi: z.string(), // Populate with the canonical domain of the advertising system where the seller.JSON file is hosted
                      sid: z.string(), // The identifier associated with the seller or reseller account within your advertising system
                      hp: z.literal(0).or(z.literal(1)), // 1 or 0, whether this node is involved in the payment flow
                    }),
                  )
                  .default([]),
              })
              .default({}),
            signals: z
              .object({
                ortb2: z
                  .object({
                    site: z
                      .object({
                        cattax: z.number().default(7),
                        cat: z.array(z.string()).default([]),
                        keywords: z.array(z.string()).default([]),
                      })
                      .default({}),
                  })
                  .default({}),
              })
              .default({}),
          })
          .default({}),
      })
      .default({}),
    [ThirdParty.AM_CLIO]: z
      .object({
        enabled: z.boolean().default(false),
      })
      .default({}),
    [ThirdParty.IAS]: z
      .object({
        enabled: z.boolean().default(false),
        pubId: z.string().default('923193'),
      })
      .default({}),
    [ThirdParty.INDEX_EXCHANGE]: z
      .object({
        enabled: z.boolean().default(false),
        consentVendor: z.string().default('10'),
        id: z.string().default(''),
        mode: z.literal('standard').default('standard'),
      })
      .default({}),
    [ThirdParty.LIVE_RAMP]: z
      .object({
        enabled: z.boolean().default(false),
        configurationId: z.string().default(''),
        consentVendor: z.string().default('97'),
      })
      .default({}),
    [ThirdParty.PUBMATIC]: z
      .object({
        enabled: z.boolean().default(false),
        profileId: z.string().default(''),
        consentVendor: z.string().default('76'),
        pubId: z.string().default('156007'),
      })
      .default({}),
    [ThirdParty.PREBID]: z
      .object({
        enabled: z.boolean().default(false),
        banner: prebidBiddersSchema.default({}),
        video: prebidBiddersSchema.default({}),
      })
      .default({}),
    [ThirdParty.EUID]: z
      .object({
        enabled: z.boolean().default(true),
      })
      .default({}),
    [ThirdParty.PUBX]: z
      .object({
        enabled: z.boolean().default(true),
      })
      .default({}),
    [ThirdParty.TMT]: z
      .object({
        enabled: z.boolean().default(false),
        configurationId: z.string().default(''),
      })
      .default({}),
    [ThirdParty.LIVE_INTENT]: z
      .object({
        enabled: z.boolean().default(true),
      })
      .default({}),
    [ThirdParty.UID2]: z
      .object({
        enabled: z.boolean().default(true),
      })
      .default({}),
  })
  .default({});
